<template>
  <base-layout>
    <h1 class="title">Company</h1>
    <label class="label">Consumer Portal Profile</label>
    <div class="columns is-vcentered">
      <div class="column is-5-tablet is-4-desktop is-3-fullscreen">
        <div class="field">
          <div class="control">
            <div class="select">
              <select v-model="selectedCompany">
                <option :value="null">Select Company</option>
                <option
                  v-for="{ cpyCode, cpyName } in companyCodes"
                  :key="cpyCode"
                  :value="cpyCode"
                  >{{ cpyName }}</option
                >
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <progress-bar />
      </div>
    </div>
    <hr />
    <validation-observer v-slot="{ invalid }">
      <validation-provider
        tag="div"
        class="field"
        v-slot="{ errors, failed }"
        rules="max:1000"
      >
        <div class="control">
          <label class="label">Company Name</label>
          <input
            type="text"
            class="input"
            v-model="companyConfig.companyName"
          />
        </div>
        <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
      </validation-provider>

      <validation-provider
        tag="div"
        class="field"
        v-slot="{ errors, failed }"
        rules="max:1000"
      >
        <div class="control">
          <label class="label">Company Phone Number</label>
          <input
            type="text"
            class="input"
            v-model="companyConfig.companyPhoneNumber"
          />
        </div>
        <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
      </validation-provider>

      <validation-provider
        tag="div"
        class="field"
        v-slot="{ errors, failed }"
        rules="max:1000"
      >
        <div class="control">
          <label class="label">Company Email</label>
          <input
            type="text"
            class="input"
            v-model="companyConfig.companyEmail"
          />
        </div>
        <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
      </validation-provider>

      <validation-provider
        tag="div"
        class="field"
        v-slot="{ errors, failed }"
        rules="max:1000"
      >
        <div class="control">
          <label class="label">Company Days Open</label>
          <input
            type="text"
            class="input"
            v-model="companyConfig.companyDaysOpen"
          />
        </div>
        <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
      </validation-provider>

      <validation-provider
        tag="div"
        class="field"
        v-slot="{ errors, failed }"
        rules="max:1000"
      >
        <div class="control">
          <label class="label">Company Hours Open</label>
          <input
            type="text"
            class="input"
            v-model="companyConfig.companyHoursOpen"
          />
        </div>
        <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
      </validation-provider>

      <validation-provider
        tag="div"
        class="field"
        v-slot="{ errors, failed }"
        rules="max:1000"
      >
        <div class="control">
          <label class="label">Company Address</label>
          <input
            type="text"
            class="input"
            v-model="companyConfig.companyAddress"
          />
        </div>
        <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
      </validation-provider>

      <validation-provider
        tag="div"
        class="field"
        v-slot="{ errors, failed }"
        rules="max:1000"
      >
        <div class="control">
          <label class="label">Company City</label>
          <input
            type="text"
            class="input"
            v-model="companyConfig.companyCity"
          />
        </div>
        <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
      </validation-provider>

      <div class="field">
        <div class="control">
          <label class="label">Company State</label>
          <div class="select is-fullwidth">
            <select id="state" v-model="companyConfig.companyState">
              <option :value="null">Please select a state</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
          </div>
        </div>
      </div>

      <validation-provider
        tag="div"
        class="field"
        v-slot="{ errors, failed }"
        rules="max:1000"
      >
        <div class="control">
          <label class="label">Company Zip Code</label>
          <input
            type="text"
            class="input"
            v-model="companyConfig.companyZipCode"
          />
        </div>
        <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
      </validation-provider>

      <div class="box">
        <label class="label">State Declaration Compliance Disclosures</label>
        <div class="field is-grouped">
          <SODSelect
            v-model="companyConfig.sdSODCode"
            :strategies="porsodStrategies"
            controlLabel=""
            nullValueLabel="Select Portal Workflow SOD"
          />
          <validation-provider
            tag="div"
            class="control is-expanded"
            v-slot="{ errors, failed }"
            rules="max:1000"
          >
            <input
              class="input"
              v-model="companyConfig.sdLabel"
              placeholder="State Disclosure Note (Optional)"
            />
            <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
          </validation-provider>
        </div>
      </div>

      <validation-provider
        tag="div"
        class="field"
        v-slot="{ errors, failed }"
        rules="max:1000"
      >
        <div class="control">
          <label class="label"
            >State Declaration Compliance Acknowledgement Acceptance Checkbox
            Verbiage</label
          >
          <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
          <input class="input" v-model="companyConfig.sdAcknowledgeLine" />
        </div>
      </validation-provider>

      <div class="box">
        <label class="label"
          >REG E - Electronic Funds Transfer Agreement and Disclosure ACH for a
          Single Payment</label
        >
        <div class="field is-grouped">
          <SODSelect
            v-model="companyConfig.rege1SODCode"
            :strategies="porsodStrategies"
            controlLabel=""
            nullValueLabel="Select Portal Workflow SOD"
          />
          <validation-provider
            tag="div"
            class="control is-expanded"
            v-slot="{ errors, failed }"
            rules="max:1000"
          >
            <input
              type="text"
              class="input"
              v-model="companyConfig.rege1Label"
              placeholder="Account Disclosure Header Note (Optional)"
            />
            <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
          </validation-provider>
        </div>
      </div>

      <div class="box">
        <label class="label"
          >REG E - Electronic Funds Transfer Agreement and Disclosure ACH for
          Multiple Payments</label
        >
        <div class="field is-grouped">
          <SODSelect
            v-model="companyConfig.rege2SODCode"
            :strategies="porsodStrategies"
            controlLabel=""
            nullValueLabel="Select Portal Workflow SOD"
          />
          <validation-provider
            tag="div"
            class="control is-expanded"
            v-slot="{ errors, failed }"
            rules="max:1000"
          >
            <input
              type="text"
              class="input"
              v-model="companyConfig.rege2Label"
              placeholder="Account Disclosure Header Note (Optional)"
            />
            <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
          </validation-provider>
        </div>
      </div>

      <div class="box">
        <label class="label"
          >REG E - Electronic Funds Transfer Agreement and Disclosure Credit
          Card for a Single Payment</label
        >
        <div class="field is-grouped">
          <SODSelect
            v-model="companyConfig.rege3SODCode"
            :strategies="porsodStrategies"
            controlLabel=""
            nullValueLabel="Select Portal Workflow SOD"
          />
          <validation-provider
            tag="div"
            class="control is-expanded"
            v-slot="{ errors, failed }"
            rules="max:1000"
          >
            <input
              type="text"
              class="input"
              v-model="companyConfig.rege3Label"
              placeholder="Account Disclosure Header Note (Optional)"
            />
            <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
          </validation-provider>
        </div>
      </div>

      <div class="box">
        <label class="label"
          >REG E - Electronic Funds Transfer Agreement and Disclosure Credit
          Card for Multiple Payments</label
        >
        <div class="field is-grouped">
          <SODSelect
            v-model="companyConfig.rege4SODCode"
            :strategies="porsodStrategies"
            controlLabel=""
            nullValueLabel="Select Portal Workflow SOD"
          />
          <validation-provider
            tag="div"
            class="control is-expanded"
            v-slot="{ errors, failed }"
            rules="max:1000"
          >
            <input
              type="text"
              class="input"
              v-model="companyConfig.rege4Label"
              placeholder="Account Disclosure Header Note (Optional)"
            />
            <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
          </validation-provider>
        </div>
      </div>

      <div class="field">
        <div class="control">
          <label class="label">Reg E Acknowledge Line</label>
          <input class="input" v-model="companyConfig.regeAcknowledgeLine" />
        </div>
      </div>

      <div class="field">
        <SODSelect
          v-model="companyConfig.confirmSODCode"
          :strategies="porsodStrategies"
          controlLabel="Pre-Submit Confirmation Message - Full and One-Time Cases"
          nullValueLabel="Select Confirmation Workflow SOD"
        />
      </div>

      <div class="field">
        <SODSelect
          v-model="companyConfig.recurringSODCode"
          :strategies="porsodStrategies"
          controlLabel="Post-Submit Success Message - Recurring Case"
          nullValueLabel="Select Recurring Workflow SOD"
        />
      </div>

      <div class="field">
        <SODSelect
          v-model="companyConfig.successSODCode"
          :strategies="porsodStrategies"
          controlLabel="Post-Submit Success Message"
          nullValueLabel="Select Success Workflow SOD"
        />
      </div>

      <div class="field">
        <div class="buttons">
          <button
            :disabled="invalid"
            class="button is-success"
            @click="savePaymentPortalCompanyConfig"
          >
            Save
          </button>
          <button class="button is-light" @click="revert">
            Revert
          </button>
        </div>
        <progress-bar />
      </div>
    </validation-observer>
  </base-layout>
</template>
<script>
import BaseLayout from "@/components/BaseLayout.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { max } from "vee-validate/dist/rules";
import SODSelect from "@/components/SODSelect.vue";
import { mapState, mapActions } from "vuex";

extend("max", {
  ...max,
  params: ["length"],
  message: "{_field_} must be fewer than {length} characters."
});

export default {
  components: {
    BaseLayout,
    ProgressBar,
    ValidationObserver,
    ValidationProvider,
    SODSelect
  },
  computed: {
    ...mapState(["porsodStrategies"])
  },
  data() {
    return {
      selectedCompany: null,
      companyCodes: [],
      companyConfig: {
        companyName: null,
        companyPhoneNumber: null,
        companyEmail: null,
        companyDaysOpen: null,
        companyHoursOpen: null,
        companyCity: null,
        companyState: null,
        companyZipCode: null,
        sdAcknowledgeLine: null,
        sdSODCode: null,
        sdLabel: null,
        regeAcknowledgeLine: null,
        rege1Label: null,
        rege1SODCode: null,
        rege2Label: null,
        rege2SODCode: null,
        rege3Label: null,
        rege3SODCode: null,
        rege4Label: null,
        rege4SODCode: null,
        confirmSODCode: null,
        successSODCode: null,
        recurringSODCode: null
      }
    };
  },
  watch: {
    selectedCompany(newCompany) {
      this.fetchPaymentPortalCompanyConfigs(newCompany);
    }
  },
  methods: {
    ...mapActions(["fetchPORSODStrategies"]),
    async fetchPaymentPortalCompanyCodes() {
      this.selectedCompany = null;
      this.companyCodes = await this.$store.dispatch(
        "fetchPaymentPortalCompanyCodes"
      );
    },
    async fetchPaymentPortalCompanyConfigs(cpyCode) {
      if (cpyCode == null) {
        this.companyConfig.companyName = null;
        this.companyConfig.companyPhoneNumber = null;
        this.companyConfig.companyEmail = null;
        this.companyConfig.companyDaysOpen = null;
        this.companyConfig.companyHoursOpen = null;
        this.companyConfig.companyCity = null;
        this.companyConfig.companyState = null;
        this.companyConfig.companyZipCode = null;
        this.companyConfig.sdAcknowledgeLine = null;
        this.companyConfig.sdSODCode = null;
        this.companyConfig.sdLabel = null;
        this.companyConfig.regeAcknowledgeLine = null;
        this.companyConfig.rege1Label = null;
        this.companyConfig.rege1SODCode = null;
        this.companyConfig.rege2Label = null;
        this.companyConfig.rege2SODCode = null;
        this.companyConfig.rege3Label = null;
        this.companyConfig.rege3SODCode = null;
        this.companyConfig.rege4Label = null;
        this.companyConfig.rege4SODCode = null;
        this.companyConfig.confirmSODCode = null;
        this.companyConfig.successSODCode = null;
        this.companyConfig.recurringSODCode = null;
        return;
      }
      this.companyConfig = await this.$store.dispatch(
        "fetchPaymentPortalCompanyConfigs",
        cpyCode
      );
    },
    async savePaymentPortalCompanyConfig() {
      if (this.selectedCompany != null) {
        let ppcc = {
          cpyCode: this.selectedCompany
        };
        Object.assign(ppcc, this.companyConfig);
        await this.$store.dispatch("savePaymentPortalCompanyConfig", ppcc);
        window.scrollTo(0, 0);
      }
    },
    async loadView() {
      this.fetchPORSODStrategies();
      this.fetchPaymentPortalCompanyCodes();
    },
    async revert() {
      await this.loadView();
      this.$store.commit("SHOW_NOTIFICATION", {
        message: `Company config data reloaded`,
        type: "success"
      });
      window.scrollTo(0, 0);
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.loadView());
  }
};
</script>
